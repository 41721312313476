.twitter-bs-wizard-tab-content {
  padding-top: 0 !important;
}

.margin-left-btn-add {
  margin-left: 5px !important;
}

.mobile-text-left {
  text-align: right;
}

.select-remitter {
  width: 200px !important;
  padding: 0rem;
  height: 30px;
  .css-yk16xz-control {
    border-color: black;
    min-height: 30px;
    max-height: 30px;
  }
  .css-26l3qy-menu {
    z-index: 99999 !important;
  }
}

@media (max-width: 991px) {
  .mobile-text-left {
    text-align: left;
    a {
      margin-left: 4px !important;
    }
  }
  .save-edit {
    position: relative !important;
  }
}
