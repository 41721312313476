//Import Icon scss
@import "./assets/scss/icons.scss";

//import App scss
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

.div-btn-add .btn-add {
    border: transparent;
}

.nav-link {
    cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
    .pagination {
        .page-link {
            border-radius: 30px !important;
            margin: 0 3px;
            border: none;
            text-align: center;
            cursor: pointer;
        }
    }
}

.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}
.dz-message {
    text-align: center;
    padding: 1rem;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.react-bootstrap-table {
    padding-top: 0px !important;
}

.square-switch {
    .react-switch-bg {
        border-radius: 5px !important;
    }
}

input[type="range"] {
    -webkit-appearance: auto !important;
}

.div-btn-add {
    align-items: center;
    display: flex;
    .btn-add {
        background: #5664d2 !important;
        padding: 3px 1rem;
        margin-left: auto;
    }
}

.page-link {
    cursor: pointer;
}

.editorClassName {
    height: auto;
    min-height: 150px;
    max-height: 100vh;
}

.link-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.Toastify__progress-bar--default {
    background: #7fa755 !important;
}

.save-edit {
    position: absolute;
}

.textarea-description {
    padding: 0.5rem;
    width: 100%;
    height: auto;
    min-height: 150px;
    max-height: 100vh;
    border-color: #ccc;
    color: #505d69;
}

.z-7{
    z-index: 7 !important;
}

.z-8{
    z-index: 8 !important;
}

.z-9{
    z-index: 9 !important;
}

.z-10{
    z-index: 10 !important;
}

.z-11{
    z-index: 11 !important;
}

.z-12{
    z-index: 12 !important;
}

.z-13{
    z-index: 13 !important;
}

.z-14{
    z-index: 14 !important;
}

.z-15{
    z-index: 15 !important;
}

.z-16{
    z-index: 16 !important;
}