.container-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  .content-modal {
    display: contents;
    background: white;
    border-radius: 5px;
    padding: 1rem;
    -webkit-box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
    .container {
      max-height: 80%;
      height: 80%;
      overflow: scroll;
      .card-body {
        height: max-content;
        min-height: 250px;
        .image-gallery-image {
          max-height: 400px;
        }
      }
    }
  }
}

.delete-banner {
  width: max-content;
  position: absolute;
  top: 0px;
  right: 1px;
  cursor: pointer;
  i {
    background: white;
    border-radius: 100%;
    padding: 2px;
    font-size: 22px;
  }
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";
