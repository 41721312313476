#vertical-menu-btn {
    visibility: hidden;
}

@media (max-width: 991px) {
    #vertical-menu-btn {
        visibility: visible;
    }
    .navbar-brand-box {
        background: #ffffff !important;
        margin-left: 1.5rem;
    }
}
