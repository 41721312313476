.pagination-rounded {
    ul {
        justify-content: center;
    }
}

.without-padding {
    padding-bottom: 0 !important;
}

.icon-in-progress-auction {
    position: absolute;
    right: 0rem;
    top: -2rem;
}

@media (max-width: 991px) {
    .icon-in-progress-auction {
        position: relative;
        right: 0rem;
        top: -0rem;
    }
}
